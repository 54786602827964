import {Component, DoCheck, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ProfileLayoutComponent} from '../profile-layout.component';
import {ActivatedRoute, Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {takeUntil} from 'rxjs/operators';
import * as utils from '../../helpers/utils';
import * as aux from 'adcore-auxiliary';
import * as cfg from '../../helpers/config';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {lastValueFrom} from 'rxjs';


@Component({
  selector: 'app-invitation',
  templateUrl: './join.component.html',
  styleUrls: ['./join.component.scss']
})
export class JoinComponent implements OnInit, DoCheck {
  constructor(private router: Router, private route: ActivatedRoute, private elementRef: ElementRef,
              @Inject(ProfileLayoutComponent) public parent: ProfileLayoutComponent, private cookieService: CookieService,
              private reCaptchaV3Service: ReCaptchaV3Service) {
  }

  // readonly strongPassword = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})';
  // readonly mediumPassword = '^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})';
  private reCaptchaScoreV3: number | undefined;
  private reCaptchaScoreV2: number | undefined;
  private disableScoreThreshold = 0.7;
  passwordRequirement = '<div>Password Requirements:</div>' +
    '<div>- At least 8 characters</div>' +
    '<div>- At least 1 uppercase letter</div>' +
    '<div>- At least 1 lowercase letter</div>' +
    '<div>- At least 1 number or special character (e.g., @, !, #)</div>';
  inProcess = false;
  uit: string | undefined;
  currentStage = 'stage1';
  stage2 = false;
  changeEmailStage = false;
  responseError: string | null = null;
  invitationSent = false;
  emailError: string | null = null;
  passwordError: string | null = null;
  fullNameError: string | null = null;
  phoneError: string | null = null;
  reSendCounter = 0;
  @ViewChild('phone') phone: aux.PhoneComponent | undefined;
  loginInfo: any = {
    token: '',
    firstName: '',
    lastName: '',
    phoneNumber: '',
    pass1: '',
    pass2: '',
    email: '',
    socialLogin: '',
    newEmail: '',
    accessCode: '',
    websiteUrl: ''
  };
  currentCountryCode = 'US';

  ngDoCheck(): void {
    this.elementRef.nativeElement.querySelectorAll(`input[type='password']`).forEach((el: any) => {
      el.setAttribute('autocomplete', 'new-password');
    });
  }

  logIn(): void {
    this.parent.winRef.reloadLocation();
  }

  get isValidEmail(): boolean {
    const re = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$');
    return re.test(this.loginInfo.email || '');
  }

  get isValidNewEmail(): boolean {
    const re = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,5}$');
    return re.test(this.loginInfo.newEmail || '') && this.loginInfo.newEmail !== this.loginInfo.email;
  }

  close(): void {
    this.parent.winRef.nativeWindow?.open('', '_self', '');
    this.parent.winRef.nativeWindow?.close();
  }

  get planImgClass(): string {
    return `plan-img ${this.loginInfo.signUpPlan}`;
  }

  ngOnInit(): void {
    this.validateReCaptchaV3();
    this.currentCountryCode = this.parent.mediaService.currentCountryCode;
    if (!this.currentCountryCode) {
      const nativeGeoIp = this.parent.winRef.nativeGeoIp;
      if (nativeGeoIp) {
        nativeGeoIp.country((geoipResponse: any) => {
            this.currentCountryCode = geoipResponse.country.iso_code;
          },
          (err: any) => {
            console.log('Error', err);
          });
      }
    }
    this.route.queryParams
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe(params => {
          this.uit = params.uit;
          const newParams = this.checkCookie(params);
          this.loginInfo.signUpSource = newParams.utm_source;
          this.loginInfo.signUpMedium = newParams.utm_medium;
          this.loginInfo.signUpCampaignName = newParams.utm_campaign;
          this.loginInfo.signUpgclid = newParams.gclid;
          this.loginInfo.signUpfbcid = newParams.fbcid;
          this.loginInfo.signUpmsclkid = newParams.msclkid;
          this.loginInfo.signUpPage = newParams.last_page;
          this.loginInfo.signUpPlan = newParams.selected_plan || 'all';
          this.loginInfo.referral_code = newParams.ref || '';
          this.loginInfo.affiliate = newParams.affiliate || '';
          this.parent.changePlan.emit(newParams.selected_plan || 'all');
          if (this.uit) {
            this.currentStage = 'stage2';
          }
          this.parent.changeStage.emit(this.currentStage);
          // console.log({li: this.loginInfo, params});
        }
      );
    this.parent?.authService.authState.subscribe(async (user) => {
      if (user) {
        if (!this.failedReCaptchaV3) {
          this.loginInfo.socialLogin = user.provider;
          this.loginInfo.firstName = user.firstName;
          this.loginInfo.lastName = user.lastName;
          this.loginInfo.email = user.email;
          await this.checkUser();
          this.stage2 = !this.emailError;
          if (this.stage2) {
            this.reCaptchaScoreV2 = undefined;
            this.currentStage = 'stage2';
            this.parent.changeStage.emit(this.currentStage);
          }
        }

      }
    });
  }

  checkCookie(params: any): any {
    if (!(params.utm_source || params.utm_medium || params.utm_campaign || params.gclid || params.fbcid ||
      params.msclkid || params.last_page || params.selected_plan || params.ref || params.affiliate)) {
      const joinCookie = this.cookieService.get(cfg.ECookieTokens.JOIN_PARAMS);
      if (joinCookie) {
        return JSON.parse(joinCookie);
      }
    } else {
      const joinCookieObj = {
        utm_source: params.utm_source,
        utm_medium: params.utm_medium,
        utm_campaign: params.utm_campaign,
        gclid: params.gclid,
        fbcid: params.fbcid,
        msclkid: params.msclkid,
        last_page: params.last_page,
        selected_plan: params.selected_plan,
        ref: params.ref,
        affiliate: params.affiliate
      };
      const joinCookie = JSON.stringify(joinCookieObj);
      this.cookieService.set(cfg.ECookieTokens.JOIN_PARAMS, joinCookie);
    }
    return params;
  }

  get isValidPhone(): boolean {
    return !aux.phoneNumberValidator(this.phone);
  }

  get fullName(): string {
    return `${this.loginInfo.firstName || ''} ${this.loginInfo.lastName || ''}`.trim();
  }

  private get isPasswordStrengthEnough(): boolean {
    const re = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9\\W])(?=.{8,})');
    return re.test(this.loginInfo.pass1 || '');
  }

  validate(): boolean {
    this.passwordError = null;
    if (this.loginInfo.pass1 && this.loginInfo.pass2 && this.loginInfo.pass1 !== this.loginInfo.pass2) {
      this.passwordError = 'Passwords not identical';
    }
    if (!this.loginInfo.socialLogin && (!this.loginInfo.pass1 || !this.loginInfo.pass2)) {
      this.passwordError = 'Please enter valid password';
    }
    if (!this.loginInfo.socialLogin && this.loginInfo.pass1 && this.loginInfo.pass2 && !this.isPasswordStrengthEnough) {
      this.passwordError = 'Please enter strong password';
    }
    this.fullNameError = null;
    if (!this.fullName.length && !this.loginInfo.socialLogin) {
      this.fullNameError = 'Please enter valid full name';
    }
    this.phoneError = null;
    if (aux.phoneNumberValidator(this.phone)) {
      this.phoneError = 'Please enter valid phone number';
    }

    return Boolean(!this.passwordError && !this.fullNameError && !this.phoneError);
  }

  get translatedTitle(): string {
    if (this.loginInfo?.signUpPlan?.toLowerCase() === 'all') {
      return 'Adcore Marketing Cloud';
    } else if (this.loginInfo?.signUpPlan?.toLowerCase() === 'feeditor_plus') {
      return 'Feeditor PLUS+';
    } else {
      return this.loginInfo?.signUpPlan;
    }
  }

  private finalizeEmailValidation(): void {
    // this.loginInfo.token = this.auit || '';
    this.parent.apiCallService
      .joinFinalization(this.loginInfo)
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        // console.log({response});
        const r = response || {};
        if (r.result && r.token) {
          this.parent.mediaService.accessToken = r.token;
          this.parent.navigateToMyCloud();
          return;
        }
        this.passwordError = r.err.toString();
      }, (reason) => {
        // console.log({reason});
        this.parent.mediaService.accessToken = null;
        // this.parent.winRef.reloadLocation();
        this.passwordError = reason.toString();
      });
  }

  /*
  reCAPTCHA
  site key: 6Lc5fGYaAAAAAIkVOv4vqGpanM4Ba-zNUqaTqeZC
  server key: 6Lc5fGYaAAAAABCxkerJiB3eMm96LUjFsfcpM1b2
  */
  private finalizeAdminInvitation(): void {
    this.inProcess = true;
    this.loginInfo.phoneNumber = this.phone?.fullPhoneNumber?.internationalNumber || '';
    this.parent.apiCallService
      .adminInvitationFinalization(this.uit || '', this.loginInfo.firstName || '', this.loginInfo.lastName || '',
        this.loginInfo.pass1 || '', this.loginInfo.pass2 || '',
        this.loginInfo.socialLogin || '', this.loginInfo.phoneNumber || '')
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        const r = response || {};
        this.inProcess = false;
        if (r.result && r.token) {
          this.parent.mediaService.accessToken = r.token;
          // this.parent.winRef.reloadLocation();
          this.parent.appAuthService.authenticate();
          this.parent.navigateToMyCloud();
          return;
        }
        this.responseError = r.error.toString();
      }, (reason) => {
        this.inProcess = false;
        this.parent.mediaService.accessToken = null;
        this.responseError = reason.toString();
        // this.parent.winRef.reloadLocation();
      });
  }

  async validateEmail(): Promise<void> {
    let user = null;
    if (!this.failedReCaptchaV3) {
      this.emailError = (!this.isValidEmail) ? 'Please enter a valid email address.' : null;
      if (this.isValidEmail) {
        user = await this.checkUser();
      }

      this.parent.winRef.reportGtagEventNew('sign-up-step-1-email', {method: 'Email', selected_plan: this.loginInfo.signUpPlan});
      if (this.loginInfo.signUpPlan === 'feeditor') {
        this.parent.winRef.reportGtagEventNew('feeditor-sign-up-step-1-email', {method: 'Email', selected_plan: this.loginInfo.signUpPlan});
      }
      this.stage2 = !this.emailError;
      if (this.stage2 && !user) {
        this.currentStage = 'stage2';
        this.parent.changeStage.emit(this.currentStage);
      }
      if (this.stage2 && user) {
        this.loginInfo = user;
        this.currentStage = 'stage3';
        this.parent.changePlan.emit(this.loginInfo.signUpPlan);
        this.parent.changeStage.emit(this.currentStage);
        this.reSendVerificationEmail();
      }
    }
  }

  async checkUser(newEmail: boolean = false): Promise<void> {
    this.inProcess = true;
    const options = {
      email: (!newEmail) ? this.loginInfo.email : this.loginInfo.newEmail,
      socialLogin: this.loginInfo.socialLogin
    };
    const res = await this.parent.apiCallService.isUserExists(options).toPromise();
    if (res) {
      this.emailError = res.error;
    }
    this.inProcess = false;
    return res;
  }

  join(): void {
    this.responseError = null;
    if (this.loginInfo.socialLogin) {
      this.phoneError = null;
      if (aux.phoneNumberValidator(this.phone)) {
        this.phoneError = 'Please enter valid phone number';
      }
      // tslint:disable-next-line:no-unused-expression
      !this.phoneError && this.joinSocial();
    } else {
      if (this.validate()) {
        if (this.uit) {
          return this.finalizeAdminInvitation();
        }
        this.joinEmail();
      }
    }
  }

  private joinSocial(): void {
    this.inProcess = true;
    this.loginInfo.signUpType = utils.capitalize(this.loginInfo.socialLogin);
    this.loginInfo.phoneNumber = this.phone?.fullPhoneNumber?.internationalNumber || '';
    this.loginInfo.reCaptchaScoreV2 = this.reCaptchaScoreV2;
    this.loginInfo.reCaptchaScoreV3 = this.reCaptchaScoreV3;
    this.parent.winRef.reportGtagEventNew('sign-up-step-1', {
      method: this.loginInfo.signUpType,
      selected_plan: this.loginInfo.signUpPlan
    });
    if (this.loginInfo.signUpPlan === 'feeditor') {
      this.parent.winRef.reportGtagEventNew('feeditor-sign-up-step-1', {
        method: this.loginInfo.signUpType,
        selected_plan: this.loginInfo.signUpPlan
      });
    }
    this.parent.apiCallService
      .joinSocialUserStage2(this.loginInfo)
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
          if (response && response.error) {
            this.responseError = response.error.toString();
          } else {
            this.parent.winRef.reportGtagEventNew('sign-up-step-1', {method: 'Email', selected_plan: this.loginInfo.signUpPlan});
            if (this.loginInfo.signUpPlan === 'feeditor') {
              this.parent.winRef.reportGtagEventNew('feeditor-sign-up-step-1', {method: 'Email', selected_plan: this.loginInfo.signUpPlan});
            }
            this.invitationSent = true;
            this.currentStage = 'stage3';
            this.parent.changeStage.emit(this.currentStage);
          }
          this.inProcess = false;
        // const r = response || {};
        // if (r.result && r.token) {
        //   setTimeout(() => {
        //     this.inProcess = false;
        //     this.parent.winRef.reportGtagEventNew('sign-up-email-verified', {method: this.loginInfo.signUpType,
        //       selected_plan: this.loginInfo.signUpPlan});
        //     this.parent.winRef.reportGtagEventNew(`signup-complete-${this.loginInfo.signUpType.toLowerCase()}`, {
        //       method: this.loginInfo.signUpType,
        //       selected_plan: this.loginInfo.signUpPlan
        //     });
        //     if (this.loginInfo.signUpPlan === 'feeditor') {
        //       this.parent.winRef.reportGtagEventNew(`feeditor-signup-complete-${this.loginInfo.signUpType.toLowerCase()}`, {
        //         method: this.loginInfo.signUpType,
        //         selected_plan: this.loginInfo.signUpPlan
        //       });
        //       this.parent.winRef.reportGtagEventNew('feeditor-sign-up-email-verified', {method: this.loginInfo.signUpType,
        //         selected_plan: this.loginInfo.signUpPlan});
        //     }
        //     this.parent.navigateToMyCloud(1000);
        //     // this.parent.winRef.reloadLocation(undefined, 1000);
        //   }, 2100);
        //   this.parent.mediaService.accessToken = r.token;
        //   return;
        // }
        // this.inProcess = false;
        // tslint:disable-next-line:no-unused-expression
        // r.error && (this.responseError = r.error.toString());
      }, (reason) => {
        // console.log({reason});
        this.inProcess = false;
        this.responseError = reason.toString();
        // this.parent.mediaService.accessToken = null;
      });
  }

  private joinEmail(): void {
    this.inProcess = true;
    this.invitationSent = false;
    this.loginInfo.signUpType = 'Email';
    this.loginInfo.phoneNumber = this.phone?.fullPhoneNumber?.internationalNumber || '';
    this.loginInfo.reCaptchaScoreV2 = this.reCaptchaScoreV2;
    this.loginInfo.reCaptchaScoreV3 = this.reCaptchaScoreV3;
    this.parent.apiCallService
      .createEmailUserStage2(this.loginInfo)
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        // console.log({response});
        if (response && response.error) {
          this.responseError = response.error.toString();
        } else {
          this.parent.winRef.reportGtagEventNew('sign-up-step-1', {method: 'Email', selected_plan: this.loginInfo.signUpPlan});
          if (this.loginInfo.signUpPlan === 'feeditor') {
            this.parent.winRef.reportGtagEventNew('feeditor-sign-up-step-1', {method: 'Email', selected_plan: this.loginInfo.signUpPlan});
          }
          this.invitationSent = true;
          this.currentStage = 'stage3';
          this.parent.changeStage.emit(this.currentStage);
        }
        this.inProcess = false;
      }, (reason) => {
        this.inProcess = false;
        this.responseError = reason.toString();
      });
  }

  private finalizeAutoEmailInvitation(accessCode: string): void {
    if (accessCode.length === 4 && !this.inProcess) {
      this.inProcess = true;
      this.loginInfo.accessCode = accessCode;
      this.parent.apiCallService
        .activateInvitedUserStage2(this.loginInfo)
        .pipe(takeUntil(this.parent.componentDestroyed))
        .subscribe((response) => {
          const r = response || {};
          if (r.result && r.token) {
            setTimeout(() => {
              this.inProcess = false;
              this.parent.winRef.reportGtagEventNew('sign-up-email-verified', {method: 'Email', selected_plan: r.selectedPlan});
              this.parent.winRef.reportGtagEventNew('signup-complete-email', {method: 'Email', selected_plan: r.selectedPlan});
              if (r.selectedPlan === 'feeditor') {
                this.parent.winRef.reportGtagEventNew('feeditor-signup-complete-email',
                  {method: 'Email', selected_plan: r.selectedPlan});
                this.parent.winRef.reportGtagEventNew('feeditor-sign-up-email-verified',
                  {method: 'Email', selected_plan:  r.selectedPlan});
              }
              this.parent.appAuthService.authenticate();
              // this.parent.winRef.reloadLocation(undefined, 1000);
              this.parent.navigateToMyCloud(1000);
            }, 2100);
            this.parent.mediaService.accessToken = r.token;
            return;
          }
          this.inProcess = false;
          // tslint:disable-next-line:no-unused-expression
          r.error && (this.responseError = r.error.toString());
        }, (reason) => {
          // console.log({reason});
          this.inProcess = false;
          this.parent.mediaService.accessToken = null;
          this.responseError = reason.toString();
        });
    }
  }

  onApplyingCode(accessCode: string): void {
    // console.log({accessCode});
    this.finalizeAutoEmailInvitation(accessCode);
  }

  reSendVerificationEmail(): void {
    this.inProcess = true;
    this.responseError = null;
    this.reSendCounter += 1;
    this.loginInfo.reSendCounter = this.reSendCounter;
    this.parent.apiCallService
      .reSendVerificationCode(this.loginInfo)
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe((response) => {
        // console.log({response});
        if (response && response.error) {
          this.responseError = response.error.toString();
        }
        this.inProcess = false;
      }, (reason) => {
        this.inProcess = false;
        this.responseError = reason.toString();
      });

  }

  changeEmailForUserStage(): void {
    this.stage2 = true;
    this.invitationSent = true;
    this.changeEmailStage = true;
  }

  async continueChangeEmail(): Promise<void> {
    this.stage2 = true;
    this.invitationSent = true;

    this.emailError = (!this.isValidNewEmail) ? 'Please enter a valid email address.' : null;

    if (this.isValidNewEmail) {
      await this.checkUser(true);
    }
    if (!this.emailError) {
      this.inProcess = true;
      this.responseError = null;
      this.parent.apiCallService
        .changeTrialUserEmail(this.loginInfo)
        .pipe(takeUntil(this.parent.componentDestroyed))
        .subscribe((response) => {
          // console.log({response});
          if (response && response.error) {
            this.responseError = response.error.toString();
          } else {
            this.loginInfo.email = this.loginInfo.newEmail;
          }
          this.inProcess = false;
        }, (reason) => {
          this.inProcess = false;
          this.responseError = reason.toString();
        });
    }
    this.changeEmailStage = false;
  }

  get formHeader(): string {
    return this.parent.jsonData[this.loginInfo.signUpPlan][this.currentStage].form.header;
  }

  get formSubHeader(): string {
    return (this.parent.jsonData[this.loginInfo.signUpPlan][this.currentStage].form.subHeader || '')
      .replace('#email#', this.loginInfo.email);
  }

  get formFooter(): string {
    return this.parent.jsonData[this.loginInfo.signUpPlan][this.currentStage].form.footer || '';
  }

  get formButton(): string {
    return (!this.uit) ? this.parent.jsonData[this.loginInfo.signUpPlan][this.currentStage].form.button || '' : 'join';
  }

  get formButtonClass(): string {
    return `${this.loginInfo.signUpPlan}-color`;
  }

  async resolveCaptchaV2(event: any): Promise<void> {
    this.reCaptchaScoreV2 = undefined;
    if (event) {
      const captcha$ = this.parent.apiCallService.validateCaptcha(event, 'JOIN2', 'V2');
      const {score} = await lastValueFrom(captcha$);
      this.reCaptchaScoreV2 = score;
      // console.log({score});
    }
  }

  private validateReCaptchaV3(): void {
    this.reCaptchaScoreV3 = undefined;
    this.reCaptchaV3Service.execute('JOIN')
      .pipe(takeUntil(this.parent.componentDestroyed))
      .subscribe(async (token: string) => {
        // console.log(`Token [${token}] generated`);
        const captcha$ = this.parent.apiCallService.validateCaptcha(token, 'JOIN');
        const {score} = await lastValueFrom(captcha$);
        this.reCaptchaScoreV3 = score;
      });

    // this.reCaptchaV3Service.onExecute.subscribe((data) =>
    //   console.log(`onExecute`, {data})
    // );
    // this.reCaptchaV3Service.onExecuteError.subscribe((data) =>
    //   console.log(`onExecuteError`, {data})
    // );
  }

  get isReCaptchaErrorV3(): boolean {
    return this.reCaptchaScoreV3 !== undefined && this.reCaptchaScoreV3 < this.disableScoreThreshold;
  }

  get isReCaptchaErrorV2(): boolean {
    return this.reCaptchaScoreV2 !== undefined && this.reCaptchaScoreV2 < this.disableScoreThreshold;
  }

  get failedReCaptchaV3(): boolean {
    return !this.reCaptchaScoreV3 || this.reCaptchaScoreV3 < this.disableScoreThreshold;
  }

  get failedReCaptchaV2(): boolean {
    return !this.reCaptchaScoreV2 || this.reCaptchaScoreV2 < this.disableScoreThreshold;
  }

}
