import {Injectable} from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams } from '@angular/common/http';
import {MediaService} from './media.service';
import {Observable, EMPTY, forkJoin, interval, timer, lastValueFrom, startWith} from 'rxjs';
import {environment} from '../../environments/environment';
import * as models from './models';
import * as aux from 'adcore-auxiliary';
import * as cfg from './config';
import {filter, mergeMap, take, takeUntil} from 'rxjs/operators';
import * as utils from './utils';

@Injectable({
  providedIn: 'root'
})
export class ApiCallService {
  private readonly hostUrl: string;

  constructor(private http: HttpClient,
              private windowRefService: aux.WindowRefService,
              private mediaService: MediaService) {
    this.hostUrl = this.windowRefService.host;
  }

  authorization(loginInfo: models.ILoginInfo): Observable<any> {
    const url = `${environment.apiUrl}/public/login`;
    return this.http.post(url, loginInfo);
  }

  authentication(): Observable<any> {
    const url = `${environment.apiUrl}/client/me_ng`;
    return this.http.post(url, {});
  }

  authenticationValidation(): Observable<any> {
    const url = `${environment.apiUrl}/public/login/validate`;
    return this.http.post(url, {token: this.mediaService.accessToken});
  }

  multipleRequests(requests: Observable<any>[]): Observable<any[]> {
    return forkJoin(requests);
  }

  getTranslations(language: string | undefined): Observable<any> {
    const url = `${environment.apiUrl}/public/translation/${language || 'en'}`;
    return this.http.get(url);
  }

  getFeedsByAdvertiser(advId: string, search: string, pagination?: any, currentFeedId?: string): Observable<any> {
    const url = `${environment.apiUrl}/client/feed/getByAdvertiser`;
    return this.http.post(url, {advId, search, pagination, currentFeedId});
  }

  validateCaptcha(token: string, action: string, version: string = 'V3'): Observable<any> {
    const url = `${environment.apiUrl}/public/join/validateCaptcha`;
    return this.http.post(url, {token, action, version});
  }

  getFeedOrViewFields(mediaType: string, mediaId: string): Observable<any> | undefined {
    const url = `${environment.apiUrl}/client/views/feedit/fields/${mediaType}/${mediaId}`;
    return this.http.get(url);
  }

  getAllPages(): Observable<any> {
    const url = `${environment.apiUrl}/client/page/get`;
    return this.http.post(url, {});
  }

  pyGettGenericLong(options: models.IOptions): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/py_gett_generic';
    options.userHash = this.mediaService.currentUserHashNumber;
    const httpHeaders = {
      headers: new HttpHeaders({
        timeout: `${1200000}`
      })
    };
    return this.http.post<any>(apiUrl, {options}, httpHeaders);
  }

  pyGettGenericShort(options: models.IOptions): Observable<any> {
    options.userHash = this.mediaService.currentUserHashNumber;
    const apiUrl = environment.apiUrl + '/client/py_gett_generic_short';
    return this.http.post<any>(apiUrl, {options});
  }

  clearSpecificFeedCache(options: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/feed/clearcahe';
    return this.http.post<any>(apiUrl, options);
  }

  sendImportDataToSaveFeed(options: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/feed/import';
    options.advertiserId = this.mediaService.selectedAdvertiserId;
    return this.http.post<any>(apiUrl, {options});
  }

  forgotPasswordFlow(username: string): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/login/forgotPassword';
    return this.http.post<any>(apiUrl, {username});
  }

  validateInvitationToken(activationToken: string): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/isInvitationAvailable';
    return this.http.post<any>(apiUrl, {activationToken});
  }

  reSendVerificationCode(userData: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/reSendVerificationCode';
    return this.http.post<any>(apiUrl, {userData});
  }
  changeTrialUserEmail(userData: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/changeTrialUserEmail';
    return this.http.post<any>(apiUrl, {userData});
  }
  validateForgotPasswordToken(token: string): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/login/validateForgotPasswordToken';
    return this.http.post<any>(apiUrl, {token});
  }

  changePassword(token: string, password: string): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/login/changePassword';
    return this.http.post<any>(apiUrl, {token, password});
  }

  invitationFinalization(token: string, firstName: string, lastName: string, pass1: string,
                         pass2: string, socialLogin: string, phoneNumber: string): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/setAndActive';
    return this.http.post<any>(apiUrl, {token, firstName, lastName, pass1, pass2, socialLogin, phoneNumber});
  }

  joinFinalization(userData: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/joinUser';
    return this.http.post<any>(apiUrl, {userData});
  }

  joinSocial(userData: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/joinSocialUser';
    return this.http.post<any>(apiUrl, {userData});
  }

  joinSocialUserStage2(userData: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/joinSocialUserStage2';
    return this.http.post<any>(apiUrl, {userData});
  }

  createEmailUserStage2(userData: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/createEmailUserStage2';
    return this.http.post<any>(apiUrl, {userData});
  }

  activateInvitedUserStage2(userData: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/activateInvitedUserStage2';
    return this.http.post<any>(apiUrl, {userData});
  }

  isBlockedCheck(username: string): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/login/isBlocked';
    return this.http.post<any>(apiUrl, {username});
  }

  autoCreateUser(userInfo: any): Observable<any> {
    // email: string
    const apiUrl = environment.apiUrl + '/public/autoCreateUser';
    return this.http.post<any>(apiUrl, userInfo);
  }

  cloneView(options: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/views/clone';
    return this.http.post<any>(apiUrl, {options});
  }

  getAllViewsByPageName(pageName?: string, currentViewId?: string): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/views/bypagename';
    return this.http.post<any>(apiUrl, {pageName, currentViewId});
  }

  getAllViews(pageId: string, pageName?: string, currentViewId?: string): Observable<any> {
    let qUrl = `/client/views/manager/${pageId}`;
    if (currentViewId) {
      qUrl += '/' + currentViewId;
    }
    if (pageName) {
      qUrl += '?pageName=' + pageName;
    }
    const apiUrl = environment.apiUrl + qUrl;
    return this.http.get<any>(apiUrl);
  }

  autoCompleteUsersEmails(options: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/autoCompleteUsersEmails';
    return this.http.post<any>(apiUrl, options);
  }

  createNewJob(options: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/jobs/new';
    return this.http.post<any>(apiUrl, options);
  }

  updateExistingJob(options: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/jobs/update';
    return this.http.post<any>(apiUrl, options);
  }

  updateJobStatus(options: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/jobs/updateStatus';
    return this.http.post<any>(apiUrl, options);
  }

  unsubscribe(options: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/semdoc/unsubscribe';
    return this.http.post<any>(apiUrl, {options});
  }

  private getRunningJob(jobId: string): Observable<any> {
    return this.pyGettGenericShort({
      module_fn: 'mongo.dbsystem.view.view.check_job_execution_status',
      advertiser_id: this.mediaService?.selectedAdvertiserId || '',
      version_id: this.mediaService?.currentVersion?.key || '',
      job_id: jobId
    });

  }

  private getScheduledProcess(mediaId: string, mediaType: string, scheduleType: string): Observable<any> {
    return this.pyGettGenericShort({
      module_fn: 'mongo.dbsystem.media.media_collection.get_process',
      advertiser_id: this.mediaService?.selectedAdvertiserId || '',
      mediaId,
      mediaType,
      scheduleType
    });
  }

  private checkScheduledProcessStatus(mediaId: string, mediaType: string, scheduleType: string): Observable<any> {
    return this.pyGettGenericShort({
      module_fn: 'mongo.dbsystem.media.media_collection.check_process_for_completion',
      advertiser_id: this.mediaService?.selectedAdvertiserId || '',
      scheduleType,
      mediaId,
      mediaType
    });
  }

  jobPoll(jobId: string, pollInterval = cfg.POLLING_INTERVAL): Observable<any> {
    return interval(pollInterval).pipe(
      mergeMap(() => this.getRunningJob(jobId)),
      filter(response => response.status === 'completed' || response.status === 'error'),
      takeUntil(timer(1800000)),
      take(1)
    );
  }

  processPoll(mediaId: string, mediaType: string, scheduleType: string, pollInterval = cfg.POLLING_INTERVAL): Observable<any> {
    return interval(pollInterval).pipe(
        startWith(0),
        mergeMap(() => this.checkScheduledProcessStatus(mediaId, mediaType, scheduleType)),
        filter(response => response.completed),
        takeUntil(timer(1800000)),
        take(1)
    );
  }

  updateUser(options: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/users/update';
    return this.http.post<any>(apiUrl, {options});
  }

  getMediaList(advertiserId: string | undefined): Observable<any> {
    const url = `${environment.apiUrl}/public/google/merchants/getMediaList/?advertiserId=${advertiserId}`;
    return this.http.get<any>(url);
  }

  downloadMiniReportFile(options: any): Observable<Blob> {
    const apiUrl = environment.apiUrl + '/client/semdoc/download-mini-report';
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'blob'
    };
    // @ts-ignore
    return this.http.post<Blob>(apiUrl, {options}, httpOptions);
  }

  postFiles(filesToUpload: File[]): Observable<any> {
    const apiUrl = `${environment.apiUrl}/admin/amc-assets/upload`;
    const formData: FormData = new FormData();

    for (const file of filesToUpload) {
      formData.append('assetFiles', file, file.name);
    }
    return this.http.post<any>(apiUrl, formData, {params: {isFileUpload: 'true'}});
  }

  getFile(assetId: string): Observable<any> {
    const apiUrl = `${environment.apiUrl}/client/amc-assets/get/${assetId}`;
    return this.http.get(apiUrl);
  }

  requestSubscriptionExtend(): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/extend/trial';
    return this.http.post<any>(apiUrl, {advertiserId: this.mediaService.selectedAdvertiserId});
  }

  isUserExists(options: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/isUserExist';
    return this.http.post<any>(apiUrl, {options});
  }

  alerterIgnore(params: any): Observable<any> {
    const apiUrl = `${environment.apiUrl}/public/alerter/ignore`;
    return this.http.post<any>(apiUrl, {params});
  }

  getAlerterIgnoreOption(params: any): Observable<any> {
    const apiUrl = `${environment.apiUrl}/public/alerter/getOption`;
    return this.http.post<any>(apiUrl, {params});
  }

  createOrUpdateContact(formData: any): Observable<any> {
    const apiUrl = `${environment.apiUrl}/public/kyc-form/contact`;
    return this.http.post<any>(apiUrl, {formData});
  }

  sendKycForm(formData: any): Observable<any> {
    const apiUrl = `${environment.apiUrl}/public/kyc-form/submit`;
    return this.http.post<any>(apiUrl, formData, {params: {isFileUpload: 'true'}});
  }

  getKycFile(fileId: any): Observable<Blob> {
    const apiUrl = `${environment.apiUrl}/public/kyc-form/getKycFile/${fileId}`;
    // @ts-ignore
    return this.http.get<Blob>(apiUrl, {responseType: 'blob'});
  }

  getBankReferenceFile(fileId: any): Observable<Blob> {
    const apiUrl = `${environment.apiUrl}/admin/bank-reference/${fileId}`;
    // @ts-ignore
    return this.http.get<Blob>(apiUrl, {responseType: 'blob'});
  }

  getKycLanguage(countryCode: string): Observable<any> {
    const apiUrl = `${environment.apiUrl}/public/kyc-form/getLanguage/${countryCode}`;
    return this.http.get<any>(apiUrl);
  }

  isInvitationAvailable(activationToken: string): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/isInvitationAvailable';
    return this.http.post<any>(apiUrl, {activationToken});
  }

  uploadProfilePhoto(image: string): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/uploadImage';
    return this.http.post<any>(apiUrl, {image});
  }

  // tslint:disable-next-line:variable-name
  changeMyPassword(userId: string, newPassword_f: string, newPassword_s: string): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/changePassword';
    return this.http.post<any>(apiUrl, {userId, newPassword_f, newPassword_s});
  }

  resetPassword(email: string): Observable<any> {
    const apiUrl = environment.apiUrl + '/client/resetPassword';
    return this.http.post<any>(apiUrl, {email});
  }

  inviteUser(options: any): Observable<any> {
    const apiUrl = environment.apiUrl + '/usersAdmin/invite';
    return this.http.post<any>(apiUrl, {options});
  }

  adminInvitationFinalization(token: string, firstName: string, lastName: string, pass1: string,
                              pass2: string, socialLogin: string, phoneNumber: string): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/finalizeAdminInvitation';
    return this.http.post<any>(apiUrl, {token, firstName, lastName, pass1, pass2, socialLogin, phoneNumber});
  }

  getCountries(): Observable<any> {
    const apiUrl = environment.apiUrl + '/public/country/get';
    return this.http.post<any>(apiUrl, {});
  }

  async reportEventHubspotAndGoogle(propName: string, extendedProp: any = null): Promise<any> {
    this.windowRefService.reportGtagEventNew(propName, {});
    const props = [{property: propName, value: utils.getUTCTimestamp()}];
    if (extendedProp) {
      props.push(extendedProp);
    }
    const contactInfo$ = this.pyGettGenericShort({
      module_fn: 'billing.flow.update_contact_info',
      email: this.mediaService.currentUser?.email,
      props});
    return  await lastValueFrom(contactInfo$); // new way instead deprecated toPromise
  }

  downloadFeedFile(format: string): Observable<Blob> {
    const apiUrl = environment.apiUrl + '/client/feeditor/download';
    const options = {
      feedId: this.mediaService.currentFeed?.key,
      viewId: this.mediaService.currentVersion?.key,
      format
    };
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'blob'
    };
    // @ts-ignore
    return this.http.post<Blob>(apiUrl, {options}, httpOptions);
  }

  async updateSelectedAdvertiser(advertiserId?: string): Promise<any> {
    if (!advertiserId) {
      advertiserId = this.mediaService.selectedAdvertiserId || '';
    }
    const getAdvertiser$ = this.pyGettGenericShort({
        module_fn: 'mongo.dbsystem.advertiser.advertisers_collection.get_advertiser',
        selected_advertiser_id: advertiserId
      }
    );
    const response = await lastValueFrom(getAdvertiser$); // new way instead deprecated toPromise()
    if (response.selectedAdvertiser) {
      const recent: aux.IMediaRow[] = this.mediaService?.recentAdvertisers || [];
      utils.addToRecentMedia(response.selectedAdvertiser, recent);
      this.mediaService.currentAdvertiser = response.selectedAdvertiser;
      this.mediaService.recentAdvertisers = recent;
    }
    return response;
  }

  downloadFileFromUrl(url: string): Observable<Blob> {
    const httpOptions = {
      responseType: 'blob'
    };
    // @ts-ignore
    return this.http.get<Blob>(url, httpOptions);
  }

  async startPromotion(options: any): Promise<any> {
    const apiUrl = environment.apiUrl + '/public/advertiser/startPromotion';
    const changeAdvertiser$ = this.http.post<any>(apiUrl, {options});
    return await lastValueFrom(changeAdvertiser$);
  }

  async completePromotion(options: any): Promise<any> {
    const apiUrl = environment.apiUrl + '/client/advertiser/completePromotion';
    const changeAdvertiser$ = this.http.post<any>(apiUrl, {options});
    return await lastValueFrom(changeAdvertiser$);
  }

  async submitFeedForAuditReview(feedInfo: any): Promise<any> {
    const apiUrl = environment.apiUrl + '/public/feed/audit';
    const requestAudit$ = this.http.post<any>(apiUrl, {feedInfo});
    return await lastValueFrom(requestAudit$);
  }

  async submitRegisteredFeedForAuditReview(feedInfo: any): Promise<any> {
    const apiUrl = environment.apiUrl + '/client/feed/audit';
    const requestAudit$ = this.http.post<any>(apiUrl, {feedInfo});
    return await lastValueFrom(requestAudit$);
  }

  async getAuditResult(auditId: any): Promise<any> {
    const apiUrl = environment.apiUrl + `/public/feed/audit/${auditId}`;
    const getAudit$ = this.http.get<any>(apiUrl);
    return await lastValueFrom(getAudit$);
  }

  downloadPdfFileByAuditId(auditId: string): Observable<Blob> {
    const apiUrl = environment.apiUrl + `/admin/feed/audit/download/a/${auditId}`;
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'blob'
    };
    // @ts-ignore
    return this.http.get<Blob>(apiUrl, httpOptions);
  }
  downloadPdfFileByProcId(procId: string): Observable<Blob> {
    const apiUrl = environment.apiUrl + `/admin/feed/audit/download/p/${procId}`;
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'blob'
    };
    // @ts-ignore
    return this.http.get<Blob>(apiUrl, httpOptions);
  }

  downloadExcelFile(accountId: string, advertiserId: string): Observable<Blob> {
    const apiUrl = environment.apiUrl + `/admin/feed/lateConversions/download/${accountId}/${advertiserId}`;
    const httpOptions = {
      headers: new HttpHeaders({'Content-Type': 'application/json'}),
      responseType: 'blob'
    };
    // @ts-ignore
    return this.http.get<Blob>(apiUrl, httpOptions);
  }

  async getLastValueRxForPy(options: any): Promise<any> {
    const sub$ = this.pyGettGenericShort(options);
    return await lastValueFrom(sub$);
  }

  async mbCheckAccountLimit(channel: string = 'google_ads', createKey: string): Promise<any> {
    if (!this.mediaService.isAdminRole) {
      const checkLimit$ = this.pyGettGenericShort({
        module_fn: 'media_blast.media_blast_ui.check_account_limit',
        advertiser: this.mediaService?.selectedAdvertiser,
        createKey,
        channel
      });
      return await lastValueFrom(checkLimit$);
    }
    return Promise.resolve({result: 'create'});
  }

  async mbGetCreateKey(): Promise<any> {
    const createKey$ = this.pyGettGenericShort({
      module_fn: 'media_blast.media_blast_ui.get_create_key'
    });
    return await lastValueFrom(createKey$);
  }

  async send2faVerification(verifyInfo: any): Promise<any> {
    const verifyResult$ = this.pyGettGenericShort({
      module_fn: 'billing.signup.send_twilio_2fa_verification',
      verifyInfo
    });
    return await lastValueFrom(verifyResult$);
  }

  async check2faVerification(verifyInfo: any): Promise<any> {
    const verifyResult$ = this.pyGettGenericShort({
      module_fn: 'billing.signup.check_twilio_2fa_verification',
      verifyInfo
    });
    return await lastValueFrom(verifyResult$);
  }

  async upgradeLogin(): Promise<any> {
    const updateResult$ = this.pyGettGenericShort({
      module_fn: 'billing.signup.finish_upgrade_login'
    });
    return await lastValueFrom(updateResult$);
  }

  async newChangePwd(userId: string, updatePwd: any): Promise<any> {
    const changeResult$ = this.pyGettGenericShort({
      module_fn: 'billing.signup.check_current_pwd',
      userId,
      updatePwd
    });
    return await lastValueFrom(changeResult$);
  }

  async changePwdWithoutOld(userId: string, newPwd: string, newPwdRepeat: string): Promise<any> {
    const changeResult$ = this.pyGettGenericShort({
      module_fn: 'billing.signup.change_pwd_without_old',
      userId,
      newPwd,
      newPwdRepeat
    });
    return await lastValueFrom(changeResult$);
  }

  async changePwdForgot(fid: string, newPwd: string, newPwdRepeat: string): Promise<any> {
    const changeResult$ = this.pyGettGenericShort({
      module_fn: 'billing.signup.change_pwd_without_old',
      fid,
      newPwd,
      newPwdRepeat
    });
    return await lastValueFrom(changeResult$);
  }

}
