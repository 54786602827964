<!--suppress HtmlUnknownAttribute -->
<div class="ml-8 login-container join" [ngClass]="parent.isDesktopDevice ? formButtonClass: 'login-mobile '+ formButtonClass">
  <div class="logo-img first-stage" [ngClass]="currentStage" *ngIf="currentStage === 'stage1'">
    <span [ngClass]="planImgClass"></span>
    <label>{{translatedTitle}}</label>
  </div>
  <ng-container *ngIf="!uit">
    <h1 class="text-center" [ngClass]="currentStage">{{formHeader}}</h1>
    <h2 class="text-center mt-2" [ngClass]="currentStage" *ngIf="formSubHeader">{{formSubHeader}}</h2>
  </ng-container>
  <ng-container *ngIf="uit">
    <h1 class="text-center">Welcome to Adcore Marketing Cloud!</h1>
    <h2 class="text-center mt-2">Complete Your Sign Up</h2>
  </ng-container>
  <ng-container *ngIf="currentStage === 'stage1'">
    <div class="justify-content-center mb-3" [ngClass]="!parent.isDesktopDevice ? 'block': 'flex'" *ngIf="!failedReCaptchaV3">
      <div class="align-g-btn">
        <asl-google-signin-button type='standard' size='large' logo_alignment="left" [width]="214" [style]="failedReCaptchaV3 ? {'pointer-events': 'none'} : {'pointer-events': 'all'}"
                                text="signup_with" shape="rectangular" theme="filled_blue"></asl-google-signin-button>
      </div>
      <div>
        <p-button label="Sign up with Facebook" icon="pi pi-facebook" styleClass="social-button social-button-facebook"
        (onClick)="parent.signInWithFB()" [style.pointer-events]="failedReCaptchaV3 ? 'none' : 'all'"></p-button>
      </div>
    </div>
    <p-divider align="center"><span class="text-uppercase">or</span></p-divider>
    <div class="field fluid" [ngStyle]="{'margin-bottom' : !parent.isDesktopDevice ? '0' : '15px'}">
      <small class="login-error mb-1" id="email-error" *ngIf="emailError">{{emailError}}</small>
      <label *ngIf="!parent.isDesktopDevice">Email</label>
      <input id="email" name="email" type="email" pInputText placeholder="Email"
             [disabled]="inProcess || !!loginInfo.socialLogin || failedReCaptchaV3"
             [(ngModel)]="loginInfo.email" autocomplete="off" [required]="true" aria-errormessage="email-error"/>
    </div>
    <div class="field fluid mb-3 text-center">
      <div class="captcha" *ngIf="isReCaptchaErrorV3">
        <small class="login-error mb-1">Google reCaptcha has detected a user with a high risk profile. Please reach out to us at <a href="mailto:amc@adcore.com" target="_blank">amc&#64;adcore.com</a> for further assistance.</small>
      </div>
      <p-button [label]="formButton" styleClass="login-btn-secondary" (onClick)="validateEmail()" *ngIf = "!failedReCaptchaV3"
                [disabled]="inProcess || !!loginInfo.socialLogin" [style.pointer-events]="failedReCaptchaV3 ? 'none' : 'all'"></p-button>
    </div>
  </ng-container>
  <form autocomplete="off" *ngIf="currentStage === 'stage2'" class="mt-2">
      <ng-container *ngIf="!loginInfo.socialLogin">
        <small class="login-error mb-1" id="fullName-error" *ngIf="fullNameError">{{fullNameError}}</small>
        <div class="flex field fluid mb-3">
          <label *ngIf="!parent.isDesktopDevice">First Name*</label>
          <input id="firstName" name="firstName" type="text" pInputText placeholder="First Name"
                 [(ngModel)]="loginInfo.firstName" autocomplete="off" [ngModelOptions]="{standalone: true}"
                 [required]="true" class="mr-3"
                 [disabled]="inProcess"/>
          <label *ngIf="!parent.isDesktopDevice">Last Name*</label>
          <input id="lastName" name="lastName" type="text" pInputText placeholder="Last Name"
                 [(ngModel)]="loginInfo.lastName" autocomplete="off" [ngModelOptions]="{standalone: true}"
                 [required]="true" [disabled]="inProcess"/>
        </div>
      </ng-container>
      <small class="login-error mb-1" id="phone-error" *ngIf="phoneError">{{phoneError}}</small>
      <div class="field fluid mb-3">
        <label *ngIf="!parent.isDesktopDevice">Mobile Number</label>
        <aux-phone #phone [countryCode]="currentCountryCode"></aux-phone>
      </div>
    <div class="field fluid mb-3">
      <label *ngIf="!parent.isDesktopDevice">Your company website</label>
      <input id="websiteUrl" name="websiteUrl" type="text" pInputText placeholder="Your company website"
             [(ngModel)]="loginInfo.websiteUrl" autocomplete="off" [ngModelOptions]="{standalone: true}"
             [required]="true" [disabled]="inProcess"/>
    </div>
      <ng-container *ngIf="!loginInfo.socialLogin">
        <div class="new-pwd-container">
          <div class="field fluid mb-3 new-pwd">
            <label *ngIf="!parent.isDesktopDevice">Enter Password</label>
            <p-password [(ngModel)]="loginInfo.pass1" [feedback]="true"
                        [toggleMask]="true" [required]="true"  [disabled]="inProcess"
                        placeholder="Enter Password" [ngModelOptions]="{standalone: true}">
            </p-password>
          </div>
          <aux-info class="center-tip" [content]="passwordRequirement"></aux-info>
        </div>
          <div class="field fluid" [ngStyle]="{'margin-bottom' : !parent.isDesktopDevice ? '0' : '15px'}">
            <label *ngIf="!parent.isDesktopDevice">Re-Enter Password</label>
            <p-password [(ngModel)]="loginInfo.pass2" [feedback]="false"
                        [toggleMask]="true" [required]="true" [disabled]="inProcess"
                        placeholder="Re-Enter Password" [ngModelOptions]="{standalone: true}"></p-password>
          </div>
          <div *ngIf="passwordError">
            <small class="login-error" id="password-error">{{passwordError}}</small>
          </div>

      </ng-container>
    <div class="p-ripple login-btn-secondary p-element p-button p-component mb-3" [attr.loading]="inProcess" *ngIf="inProcess">
      <div class="lds-ellipsis">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <re-captcha id="recaptcha" style="margin: auto;" (resolved)="resolveCaptchaV2($event)" siteKey="6LcKpmQpAAAAALmBtiOrHTesplMwqC6u1BfM8biI" size="normal"  theme="light" type="image"  data-type="image" data-action="JOIN2"></re-captcha>
    <p-button [label]="formButton" styleClass="login-btn-secondary mb-3" (onClick)="join()" *ngIf="!inProcess && !failedReCaptchaV2"></p-button>
    <div *ngIf="responseError">
      <small class="login-error" id="response-error1">{{responseError}}</small>
    </div>
  </form>
  <div class="field fluid mt-2 text-center" *ngIf="currentStage === 'stage3'">
    <aux-digit-code (applyingCode)="onApplyingCode($event)" [disabled]="inProcess"></aux-digit-code>
    <div class="verify-links">
      <p-button styleClass="p-button-link" label="Resend verification code" (onClick)="reSendVerificationEmail()" [disabled]="inProcess" [link]="true"></p-button>
      <ng-container *ngIf="!loginInfo.socialLogin">
        <div class="or">OR</div>
        <p-button styleClass="p-button-link" label="Change email address" (onClick)="changeEmailForUserStage()" [disabled]="inProcess" [link]="true"></p-button>
      </ng-container>

    </div>

    <div class="change-e" *ngIf="changeEmailStage">
      <div class="field fluid" [ngStyle]="{'margin-bottom' : !parent.isDesktopDevice ? '0' : '15px'}">
        <small class="login-error mb-1" id="new-email-error" *ngIf="emailError">{{emailError}}</small>
        <label *ngIf="!parent.isDesktopDevice">Email</label>
        <input id="newEmail" name="email" type="email" pInputText placeholder="Email"
               [disabled]="inProcess"
               [(ngModel)]="loginInfo.newEmail" autocomplete="off" [required]="true" aria-errormessage="new-email-error"/>
      </div>
      <div class="field fluid mb-3 text-center">
        <p-button [label]="formButton" styleClass="login-btn-secondary" (onClick)="continueChangeEmail()"
                  [disabled]="inProcess"></p-button>
      </div>
    </div>

    <div *ngIf="responseError" class="text-center">
      <h3 class="login-error" id="response-error2">{{responseError}}</h3>
    </div>
  </div>
  <div class="text-center mt-1 login-explain" *ngIf="formFooter" [ngClass]="{'large-text' : currentStage === 'stage1'}"><small [innerHTML]="formFooter"></small></div>
  <div class="text-center mb-3 user-log" *ngIf="currentStage === 'stage1'">
    <div class="new-user">Already have account?</div>
    <p-button styleClass="p-button-link" label="Log in" (onClick)="logIn()" [link]="true"></p-button>
  </div>
</div>
